<template>
  <v-container>
    <v-card>
      <v-card-title
        >Mailing Liste
        {{ (currentListAddress && "bearbeiten") || "erstellen" }}</v-card-title
      >
      <v-card-text>
        <v-form ref="listForm">
          <v-row>
            <v-col>
              <v-text-field
                v-model="list.name"
                label="Name"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="listAddressWithoutDomain"
                label="Adresse"
                outlined
                dense
                :rules="[validators.atleastNChars(4)]"
                :suffix="domainForDispaly"
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>

          <v-text-field
            v-model="list.description"
            label="Beschreibung"
            outlined
            dense
          ></v-text-field>
          <v-btn class="success" @click="saveList">Speichern</v-btn>
        </v-form>
        <div v-if="currentListAddress !== undefined">
          <v-row>
            <v-col>
              <v-autocomplete
                label="Person hinzufügen"
                v-model="selectedPerson"
                :items="selectablePersons"
                item-text="name"
                item-value="id"
                return-object
                ref="personSelect"
                class="mx-3"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      [
                        [data.item.zip_code, data.item.city]
                          .filter((e) => e)
                          .join(" "),
                        data.item.email,
                      ]
                        .filter((e) => e)
                        .join(" / ")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Suchen..."
                single-line
                hide-details
                class="mx-3"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-dialog v-model="manualAddDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                small
                class="mb-3 ml-3"
                style="margin-top: -10px"
                >Manuell erfassen</v-btn
              >
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2"
                >Adresse manuell erfassen</v-card-title
              >
              <v-card-text>
                <v-form ref="manualAddForm">
                  <v-text-field
                    v-model="manualAddPerson.first_name"
                    label="Name"
                    :rules="[validators.atleastNChars(4)]"
                    validate-on-blur
                  ></v-text-field>
                  <v-text-field
                    v-model="manualAddPerson.email"
                    label="Email"
                    :rules="validators.isEmailAddress"
                    validate-on-blur
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="error"
                  outlined
                  @click="
                    $refs.manualAddForm.reset();
                    manualAddDialog = false;
                  "
                  >Abbrechen</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn color="success" outlined @click="saveManualAddForm"
                  >Hinzufügen</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-data-table
            :headers="headers"
            :items="members"
            :search="search"
            :items-per-page="15"
            :footer-props="{ 'items-per-page-options': [15, 25, 50, 100] }"
          >
            <template v-slot:[`item.status`]="{ item }">
              <status-icon
                :status="true"
                :status-color="item.subscribed ? 'green' : 'red'"
                icon="mdi-email"
                :tooltip="`${
                  item.subscribed ? 'Erhält Emails.' : 'Hat sich abgemeldet.'
                }`"
              />
              <status-icon
                v-if="item.vars.type !== 'person'"
                icon="mdi-account-edit"
                :status="false"
                tooltip="Manuell hinzugefügt"
              />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon @click="removePerson(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-row>
            <v-col
              >Hinweise zu den Mailing Listen:
              <ul class="text-caption">
                <li>
                  Die Email Addressen der Mitglieder werden täglich mit den
                  Daten vom SAC Zentralverband aktualisiert.
                </li>
                <li>
                  Manuell erfasste Email Addressen werden nicht aktualisiert.
                </li>
                <li>
                  Jeder Empfänger kann sich, über einen Link in jeder Nachricht,
                  von der Liste entfernen.
                </li>
              </ul>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'pinia'

import emailService from '@/services/email'
import { createNotification } from '@/utils'
import { usePersonStore } from '@/stores/person'

const EMAIL_DOMAIN = 'mg.sac-toedi.ch'
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/


export default {
  name: 'ListEditor',
  data() {
    return {
      list: {
        name: '',
        description: '',
        address: '',
      },
      validators: {
        isEmailAddress: [
          value => value !== undefined && (EMAIL_REGEX.test(value) || 'Ungültige Email'),
        ],
        atleastNChars(n) {
          return (value) => {
            return value !== undefined && (value.length >= n || `Mindestens ${n} Zeichen erforderlich.`)
          }
        },
      },
      members: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'address' },
        { text: 'Status', value: 'status' },
        { text: '', value: 'actions', sortable: false, align: 'right' },
      ],
      search: '',
      selectedPerson: null,
      manualAddDialog: false,
      manualAddPerson: {
        first_name: '',
        last_name: '',
        email: '',
      },
      emailDomain: EMAIL_DOMAIN,
    }
  },
  computed: {
    currentListAddress() {
      return this.$route.params.address
    },
    listAddressWithoutDomain: {
      get: function () {
        return this.list.address.replace(`@${this.emailDomain}`, '')
      },
      set: function (val) {
        this.list.address = `${val}@${this.emailDomain}`
      },
    },
    domainForDispaly() {
      return '@' + this.emailDomain
    },
    selectablePersons() {
      return this.persons.filter(e => e.email)
        .filter(e => !this.members.some(obj => obj.address === e.email))
    },
    ...mapState(usePersonStore, { persons: 'personList' }),
  },
  methods: {
    loadMailingList(address) {
      emailService.getMailingList(address)
        .then(data => {
          this.list = data
          return emailService.getMailingListMembers(address)
        })
        .then(data => {
          this.members = data
        })
    },
    addPerson(person, type) {
      const data = {
        address: person.email,
        name: `${person.first_name} ${person.last_name}`,
        vars: {
          created: (new Date()).toISOString(),
          type: type,
        },
      }
      if (type === 'person') {
        data.vars.id = person.id
      }
      data.vars = JSON.stringify(data.vars)
      return emailService.addMailingListMember(this.currentListAddress, data)
        .then(data => {
          this.members.push(data)
          createNotification(`${data.name} zur Mailingliste hinzugefügt.`, 'success')
        }).catch(error => {
          console.log(error)
          createNotification('Person hinzufügen fehlgeschlagen!', 'error')
        })
    },
    removePerson(person) {
      emailService.removeMailingListMember(this.currentListAddress, person.address)
        .then(data => {
          this.members = this.members.filter(e => e.address !== data.address)
          createNotification(`${person.name} von der Mailingliste entfernt.`, 'success')
        })
    },
    saveList() {
      if (!this.$refs.listForm.validate()) {
        return
      }
      let promise
      if (this.currentListAddress === undefined) {
        promise = emailService.createMailingList(this.list)
      } else {
        promise = emailService.updateMailingList(this.currentListAddress, this.list)
      }
      promise.then(data => {
        this.list = data
        if (data.address !== this.currentListAddress) {
          this.$router.push({ name: 'MailListEdit', params: { address: data.address } })
        }
        createNotification('Änderungen gespeichert!', 'success')
      }).catch(error => {
        console.log(error)
        createNotification('Speichern fehlgeschlagen!', 'error')
      })
    },
    saveManualAddForm() {
      if (!this.$refs.manualAddForm.validate()) {
        return
      }
      this.addPerson(this.manualAddPerson, 'manual')
        .then(() => {
          this.manualAddPerson.first_name = ''
          this.manualAddPerson.email = ''
          this.manualAddDialog = false
          this.$refs.manualAddForm.resetValidation()
        }).catch(error => {
          console.log(error)
          createNotification('Person hinzufügen fehlgeschlagen!', 'error')
        })
    },
  },
  watch: {
    selectedPerson(newVal) {
      if (newVal === null) {
        return
      }
      this.addPerson(newVal, 'person')
      setTimeout(() => {
        // Reset the field
        this.$refs.personSelect.blur()
        this.selectedPerson = null
        // And focus again after a short timeout
        setTimeout(() => this.$refs.personSelect.focus(), 50)
      }, 400)
    },
  },
  mounted() {
    emailService.getMetaData().then(data => {
      this.emailDomain = data.domain
    })
    if (this.currentListAddress !== undefined) {
      this.loadMailingList(this.currentListAddress)
    }
  },
}
</script>
